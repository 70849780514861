import React, { createContext, useState, useEffect } from "react"
import { useTranslation } from "react-i18next"

export const NavContext = createContext()

const NavContextProvider = ({ children }) => {
  const [navData, setNavData] = useState({})
  const { i18n } = useTranslation()

  const navigationPath = (language) => {
    let path = window.location.origin + "/navigations/navigations.json"
    let originalHost = process.env.GATSBY_ORIGINAL_HOST
    if (process.env.GATSBY_USE_FOLDER_AS_LANGUAGE) {
      path =
        originalHost +
        "/" +
        process.env.GATSBY_GLOBAL_PATH_PREFIX +
        process.env.GATSBY_GLOBAL_PATH_PREFIX_DELIMITER +
        language +
        "/navigations/navigations.json"
    }
    return path
  }

  useEffect(() => {
    fetch(navigationPath(i18n.language))
      .then(function (response) {
        if (!response.ok) {
          throw Error(response.statusText)
        }
        return response.json()
      })
      .then(function (data) {
        setNavData(data)
      })
      .catch(function (error) {
        console.log("RO navigation error")
        setNavData({})
      })

    return () => {
      setNavData({})
    }
  }, [i18n, i18n.language])

  return (
    <NavContext.Provider value={{ navData }}>{children}</NavContext.Provider>
  )
}

export default NavContextProvider

import i18n from "i18next"
import { initReactI18next } from "react-i18next"

i18n.use(initReactI18next).init({
  fallbackLng: "en",
  resources: {
    cz: {
      translations: require("./translations.cz.json"),
    },
    bg: {
      translations: require("./translations.bg.json"),
    },
    de: {
      translations: require("./translations.de.json"),
    },
    en: {
      translations: require("./translations.en.json"),
    },
    es: {
      translations: require("./translations.es.json"),
    },
    fr: {
      translations: require("./translations.fr.json"),
    },
    hu: {
      translations: require("./translations.hu.json"),
    },
    it: {
      translations: require("./translations.it.json"),
    },
    lv: {
      translations: require("./translations.lv.json"),
    },
    nl: {
      translations: require("./translations.nl.json"),
    },
    pl: {
      translations: require("./translations.pl"),
    },
    pt: {
      translations: require("./translations.pt.json"),
    },
    ro: {
      translations: require("./translations.ro.json"),
    },
    sk: {
      translations: require("./translations.sk.json"),
    },
    tr: {
      translations: require("./translations.tr.json"),
    },
  },
  ns: ["translations"],
  defaultNS: "translations",
  returnObjects: true,
  debug: false, //process.env.NODE_ENV === "development",
  interpolation: {
    escapeValue: false, // not needed for react!!
  },
  react: {
    wait: true,
  },
})

i18n.languages = [
  "bg",
  "cz",
  "de",
  "en",
  "es",
  "fr",
  "hu",
  "it",
  "nl",
  "pl",
  "pt",
  "sk",
  "tr",
]

export default i18n

// OLD Stuff
// // i18n
// import translationDE from "../../themes/gatsby-theme-rothenberger/src/locales/translations.de.json"
// import translationEN from "../../themes/gatsby-theme-rothenberger/src/locales/translations.en.json"
// import translationFR from "../../themes/gatsby-theme-rothenberger/src/locales/translations.fr.json"
// import translationNL from "../../themes/gatsby-theme-rothenberger/src/locales/translations.nl.json"
// i18n.use(initReactI18next).init({
//   fallbackLng: "en",
//   resources: {
//     de: {
//       translations: translationDE,
//     },
//     en: {
//       translations: translationEN,
//     },
//     fr: {
//       translations: translationFR,
//     },
//     nl: {
//       translations: translationNL,
//     },
//   },
//   ns: ["translations"],
//   defaultNS: "translations",
//   returnObjects: true,
//   debug: process.env.NODE_ENV === "development",
//   interpolation: {
//     escapeValue: false,
//   },
//   react: {
//     wait: true,
//   },
// })

import React from "react"
import { Auth0Provider } from "@auth0/auth0-react"
import { navigate } from "gatsby"
import { I18nContext } from "./src/context/I18nContext"
import "../../node_modules/@yumgmbh/gatsby-theme-yum-components/src/scss/style.scss"

require("typeface-inter")

const onRedirectCallback = (appState) => {
  if (
    window &&
    window.location &&
    window.location.href &&
    appState?.targetUrl
  ) {
    window.location.href = appState?.targetUrl
  }
}

const wrapRootElement = ({ element }) => {
  if (
    window.location.pathname === "/storyblok-preview" ||
    window.location.pathname === "/storyblok-preview/"
  ) {
    return <>{element}</>
  } else {
    return (
      <Auth0Provider
        domain={process.env.GATSBY_AUTH0_DOMAIN}
        clientId={process.env.GATSBY_AUTH0_CLIENT_ID}
        //organization="YOUR_ORGANIZATION_ID"
        redirectUri={
          window.location.origin +
          "/" +
          window.location.pathname.split("/")[1] +
          "/"
        }
        onRedirectCallback={onRedirectCallback}
      >
        {element}
      </Auth0Provider>
    )
  }
}

const wrapPageElement = ({ element, props }) => {
  return <I18nContext pageContext={props.pageContext}>{element}</I18nContext>
}

export { wrapPageElement, wrapRootElement }

export const onRouteUpdate = ({ location, prevLocation }) => {
  if (process.env.NODE_ENV === `production` || `development`) {
    setTimeout(() => {
      if (window && window.ga) {
        window.ga("set", "page", location.pathname)
        window.ga("send", "pageview")
      }
    }, 50)
  }
}
